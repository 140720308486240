//////////////////////////////////////////////////
////// Startseite

//////////////////////////////////////////////////
// variables

var $ci1 = j.el('.ci1'),
    $cr = j.el('.crop'),
    $mask_fullscreen = j.el('.mask_fullscreen'),
	$viewport_fullscreen = j.el('.viewport_fullscreen'),

    // sass vars
    _$overlap = 2,
	_$arcHeight = 19.61,
    _$fullArcHeight = _$arcHeight + _$overlap,
    _$circleBorder = 49.02

//////////////////////////////////////////////////
// events

j.on(
	window, {
		_resize: viewportChange,
		orientationchange: viewportChange

	}
);
j.on(
	"a", {
		click: function (e, el) {
			if (el.hash !== "") {
				e.preventDefault();
				var hash = el.hash,
					offset = j.offset.document.top(j.el(hash)) + 50*j.vh(),
					scroll = j.scroll.top(),
					dur = 2000;
				location.hash = hash;
				j.animate(
					function (t) { document.documentElement.scrollTop = j.lerp( scroll, offset )( j.ease.inout(3)(t/dur) ); }, dur
				);

			}
		}
	}
);

function viewportChange () {
	var vw = j.vw(),
		vh = j.vh(),
		em = ( 100*vw <= 1000 || 100*vh <= 500 ) ? 16 * 1.1 : 16 * 1.2
	
	j.css(
		// set arc height of curved sections
		$cr, {
			height: _$fullArcHeight*vw // 19.61% + 2% overlap
		},
		$ci1, {
			borderWidth: _$circleBorder*vw
		},
		// calculate fullscreen block properties
		$mask_fullscreen, {
			height: 100*vh
		},
		$viewport_fullscreen, {
			width: 100*vw,
			height: 100*vh
		}
	)
};

//////////////////////////////////////////////////
// svg

// clock
var updateClock = (function () {
	var d = new Date(),
		hourhand = 360 * (d.getHours() % 12) / 12,
		minutehand = 360 * d.getMinutes() / 60;
	j.el("#hourhand").setAttribute("transform", "rotate (" + (hourhand + minutehand / 12) + " 97.5 97.5)");
	j.el("#minutehand").setAttribute("transform", "rotate (" + minutehand + " 97.5 97.5)");
})();
setInterval(updateClock, 15000); // update clock every 15 s


// stroke-dashoffset-animation
(function () {
	var svgParents = j.el(".svg-icon"),
		types = ["circle", "ellipse", "line", "path", "polygon", "polyline", "rect"]
	if (!window.matchMedia("screen and (max-width: 1000px), screen and (max-height: 700px)").matches) {
		let i = svgParents.length; while (i--) {
			var svgEls = [],
			k = types.length; while (k--) {
				var elsOfType = svgParents[i].getElementsByTagName(types[k])
				if (elsOfType[0]) svgEls.push(elsOfType)
			}
			styleSVG(svgEls);
			animateSVG(svgParents[i], svgEls);
		}
	}

})();

function styleSVG(svgEls) {
	j.all(
		svgEls,
		function (el) {
			if (el.getTotalLength) {
				j.attr(el, {
					"stroke-dasharray": el.getTotalLength() + " " + (el.getTotalLength() + 40),
					"stroke-dashoffset": el.getTotalLength() + 20
				});
			}
		}
	);
}
function animateSVG(svgParent, svgEls) {
	if (j.scroll.top() + 80*j.vh() >= j.offset.document.top(svgParent) ) {
		var dur = 1500;
		j.all(
			svgEls,
			function (el) {
				if (el.getTotalLength && j.css(el, "stroke") !== "none") {
					var length = el.getTotalLength() + 20
					j.animate(
						function (t) {
							j.attr(el, {
								"stroke-dashoffset": j.lerp( length, 0 )( j.ease.inout()(t/dur) )
							});
						}, dur
					);
				}
			}
		);
	} else {
		setTimeout(function () { animateSVG(svgParent, svgEls) }, 100); // waiting for scroll
	}
}


//////////////////////////////////////////////////
// info box

if ( j.el("#info-box") ) {
	j.on(
		[j.el(".close")[0], j.el("main")[0]], {
			click: function (e) {
				var parent = j.el(".close")[0].parentNode,
					dur = 500;
				j.animate(
					function (t) {
						j.css(parent, {
							opacity: j.lerp( 1, 0 )( j.ease.inout(3)(t/dur) )}
						);
					},
					function (t) { if (t >= dur) {
						j.css(parent, {display: "none"});
						return true;
					}}
				);
			}
		}
	);

}