//////////////////////////////////////////////////
////// navigation

var $menu = [j.el("#header"), j.el("#menu")],
	$menu_top = j.el("#svg_menu_top"),
	$menu_middle = j.el("#svg_menu_middle"),
	$menu_bottom = j.el("#svg_menu_bottom")

j.on(
	[].slice.call(j.el(".button_menu")).concat([].slice.call(j.el("#menu").children)),
	{
		click: function (e) {
			
			var dur = 500,
				reverse = (parseInt( $menu[0].style.height || 0 ) <= 80),
				tween = function (from, to, t) { return j.lerp( reverse ? to : from, reverse ? from : to )( j.ease.inout()(t/dur) ); }
			
			j.animate(
				function (t) {
					j.css(
						$menu, {
							height: tween( 100 * j.vh(), 70, t ) + "px"
						}
					);
					j.attr(
						$menu_top, {
							"y1": tween( 15, 5.445, t ),
							"y2": tween( 15, 5.445, t ),
							"transform": "rotate(" + tween( 45, 0, t ) + ")"
						}
					);
					j.attr(
						$menu_middle, {
							"transform": "scale(" + tween( 0, 1, t ) + ", 1)"
						}
					);
					j.attr(
						$menu_bottom, {
							"y1": tween( 15, 24.555, t ),
							"y2": tween( 15, 24.555, t ),
							"transform": "rotate(" + tween( -45, 0, t ) + ")"
						}
					);
				}, dur
			);
		}
	}
);


